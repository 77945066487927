/*------ Tables -----*/

.table {
	.text-wrap table {
		width: 100%;
		max-width: 100%;
		margin-bottom: 1rem;
	}
	th {
		padding: 0.75rem;
		vertical-align: top;
		border-top: 1px solid $border;
	}
}

.text-wrap table th td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid $border;
}

.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 0;
}

.ico-listing.table {
	td,
	th {
		padding: .75rem;
		vertical-align: middle;
	}
}

.border {
	
	vertical-align: top;
	border: 1px solid $border;
}

.table thead th,
.text-wrap table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #e8ebf3;
}

.table tbody+tbody,
.text-wrap table tbody+tbody {
	border-top: 2px solid #e8ebf3;
}

.table .table,
.text-wrap table .table,
.table .text-wrap table {
	background-color: #f9faff;
}

.text-wrap {
	.table table,
	table table {
		background-color: #f9faff;
	}
}

.table-sm {
	th,
	td {
		padding: 0.3rem;
	}
}

.table-bordered,
.text-wrap table,
.table-bordered th,
.text-wrap table th,
.table-bordered td,
.text-wrap table td {
	border: 1px solid $border;
}

.table-bordered thead th,
.text-wrap table thead th,
.table-bordered thead td,
.text-wrap table thead td {
	border-bottom-width: 2px;
}

.table-borderless {
	th,
	td,
	thead th,
	tbody+tbody {
		border: 0;
	}
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.02);
}

.table-hover tbody tr:hover {
	background-color: #f9faff;
}

.table-primary {
	background-color: #e6f2fe;
	> {
		th,
		td {
			background-color: #e6f2fe;
		}
	}
}

.table-hover .table-primary:hover {
	background-color: #b7cded;
	> {
		td,
		th {
			background-color: #b7cded;
		}
	}
}

.table-secondary {
	background-color: #dddfe2;
	> {
		th,
		td {
			background-color: #dddfe2;
		}
	}
}

.table-hover .table-secondary:hover {
	background-color: #cfd2d6;
	> {
		td,
		th {
			background-color: #cfd2d6;
		}
	}
}

.table-success {
	background-color: #c9fde7;
	> {
		th,
		td {
			background-color: #c9fde7;
		}
	}
}

.table-hover .table-success:hover {
	background-color: #c5e7a4;
	> {
		td,
		th {
			background-color: #c5e7a4;
		}
	}
}

.table-info {
	background-color: #cbe7fb;
	> {
		th,
		td {
			background-color: #cbe7fb;
		}
	}
}

.table-hover .table-info:hover {
	background-color: #b3dcf9;
	> {
		td,
		th {
			background-color: #b3dcf9;
		}
	}
}

.table-warning {
	background-color: #fbeebc;
	> {
		th,
		td {
			background-color: #fbeebc;
		}
	}
}

.table-hover .table-warning:hover {
	background-color: #fae8a4;
	> {
		td,
		th {
			background-color: #fae8a4;
		}
	}
}

.table-danger {
	background-color: #f1c1c0;
	> {
		th,
		td {
			background-color: #f1c1c0;
		}
	}
}

.table-hover .table-danger:hover {
	background-color: #ecacab;
	> {
		td,
		th {
			background-color: #ecacab;
		}
	}
}

.table-light {
	background-color: #fdfdfe;
	> {
		th,
		td {
			background-color: #fdfdfe;
		}
	}
}

.table-hover .table-light:hover {
	background-color: #ececf6;
	> {
		td,
		th {
			background-color: #ececf6;
		}
	}
}

.table-dark {
	background-color: #c6c8ca;
	> {
		th,
		td {
			background-color: #c6c8ca;
		}
	}
}

.table-hover .table-dark:hover {
	background-color: #b9bbbe;
	> {
		td,
		th {
			background-color: #b9bbbe;
		}
	}
}

.table-active {
	background-color: rgba(0, 0, 0, 0.04);
	> {
		th,
		td {
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.04);
	> {
		td,
		th {
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
}

.table .thead-dark th,
.text-wrap table .thead-dark th {
	color: #f9faff;
	background-color: #a7b4c9;
	border-color: #32383e;
}

.table .thead-light th,
.text-wrap table .thead-light th {
	color: #3d4e67;
	background-color: #e8ebf3;
	border-color: #e8ebf3;
}

.table-dark {
	color: #f9faff;
	background-color: #a7b4c9;
	th,
	td,
	thead th {
		border-color: #32383e;
	}
	&.table-bordered {
		border: 0;
	}
}

.text-wrap table.table-dark {
	border: 0;
}

.table-dark {
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $white-05;
	}
	&.table-hover tbody tr:hover {
		background-color: $white-75;
	}
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		>.table-bordered {
			border: 0;
		}
	}
	.text-wrap .table-responsive-sm>table {
		border: 0;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		>.table-bordered {
			border: 0;
		}
	}
	.text-wrap .table-responsive-md>table {
		border: 0;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		>.table-bordered {
			border: 0;
		}
	}
	.text-wrap .table-responsive-lg>table {
		border: 0;
	}
}

@media (max-width: 1279.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		>.table-bordered {
			border: 0;
		}
	}
	.text-wrap .table-responsive-xl>table {
		border: 0;
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	>.table-bordered {
		border: 0;
	}
}

.text-wrap .table-responsive>table {
	border: 0;
}

table {
	border-collapse: collapse;
	&.dataTable {
		tfoot {
			th,
			td {
				border-top: 0 !important;
			}
		}
		thead {
			th,
			td {
				border-bottom: 0 !important;
			}
		}
		&.no-footer {
			border-bottom: 1px solid $border !important;
		}
	}
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 0 !important;
	margin: 0 !important;
	border: 0 !important;
}

table.dataTable {
	&.hover tbody tr:hover,
	&.display tbody tr:hover {
		background-color: #eeeef9 !important;
	}
}

.table thead th,
.text-wrap table thead th {
	border-top: 0;
	border-bottom-width: 1px;
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.table th,
.text-wrap table th {
	color: $color;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 400;
}

.table-md {
	th,
	td {
		padding: .5rem;
	}
}

.table-vcenter {
	td,
	th {
		vertical-align: middle;
		border-top: 1px solid $border;
	}
}

.table-center {
	td,
	th {
		text-align: center;
	}
}

.table-striped tbody tr {
	&:nth-of-type(odd) {
		background: transparent;
	}
	&:nth-of-type(even) {
		background-color: #f9faff;
	}
}

.table-calendar {
	margin: 0 0 .75rem;
	td,
	th {
		border: 0;
		text-align: center;
		padding: 0 !important;
		width: 14.28571429%;
		line-height: 2.5rem;
	}
	td {
		border-top: 0;
	}
}

.table-calendar-link {
	line-height: 2rem;
	min-width: calc(2rem + 2px);
	display: inline-block;
	border-radius: 3px;
	background: #f8f9fa;
	color: #3d4e67;
	font-weight: 600;
	-webkit-transition: .3s background, .3s color;
	transition: .3s background, .3s color;
	position: relative;
	&:before {
		content: '';
		width: 4px;
		height: 4px;
		position: absolute;
		left: .25rem;
		top: .25rem;
		border-radius: 50px;
		background: $blue;
	}
	&:hover {
		color: $white;
		text-decoration: none;
		background: $blue;
		-webkit-transition: .3s background;
		transition: .3s background;
		&:before {
			background: $white;
		}
	}
}

.table-header {
	cursor: pointer;
	-webkit-transition: .3s color;
	transition: .3s color;
	&:hover {
		color: #3d4e67 !important;
	}
	&:after {
		content: '\f0dc';
		font-family: FontAwesome;
		display: inline-block;
		margin-left: .5rem;
		font-size: .75rem;
	}
}

.table-header-asc {
	color: #3d4e67 !important;
	&:after {
		content: '\f0de';
	}
}

.table-header-desc {
	color: #3d4e67 !important;
	&:after {
		content: '\f0dd';
	}
}

.table-inbox {
	border: 1px solid $border;
	margin-bottom: 0;
	tr {
		border-bottom: 1px solid $border;
		&:last-child {
			border-bottom: 0;
		}
		td {
			padding: 12px !important;
			&:hover {
				cursor: pointer;
			}
			.fa-star {
				color: rgba(106, 221, 196, 0.06);
				&.inbox-started,
				&:hover {
					color: #f78a09;
				}
			}
		}
		&.unread td {
			background: #f9faff;
			font-weight: 600;
			border-bottom: #e8ebf3 1px solid;
		}
	}
}

.table>:not(caption)>*>* {
    background-color: transparent;
    box-shadow: none;
}
table.table-bordered.dataTable th:first-child, table.table-bordered.dataTable th:first-child, table.table-bordered.dataTable td:first-child, table.table-bordered.dataTable td:first-child {
    border-left-width: 0 !important;
}
table.table-bordered.dataTable th, table.table-bordered.dataTable td {
    border-bottom-width: 0 !important;
}

/*------ Tables -----*/

